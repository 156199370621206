.containerall{
background-color: rgba(255, 148, 55, 0.847) ;
}

.content_11ul{
    color: rgb(255, 0, 0);
}

.content_11ul a{
  text-decoration: none;
  color: black;
}

.list-group-item{
    color: rgba(255, 148, 55, 0.847);
}

.iconall {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iconall i {
    color: rgb(255, 0, 0);
    margin: 0 10px;
    font-size: x-large;
  }

  .Tuni {
    color: rgb(20, 18, 18);
    font-size: large;
    font-weight: bolder;
  }

  .content_11ul a:hover{
    color: aliceblue;
  }

.content_11ula a:hover{
  color: brown;
}

.iconall i:hover{
  color: aliceblue;
}