.JoinOurTeam_content {
    align-items: center;
    text-align: start;
}

.JoinOurTeam_content h1 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.JoinOurTeam_row1{
    background-color: rgb(255, 235, 235);
    margin-top: 30px;
    margin-bottom: 30px;
}

.JoinOurTeam_row2{
    background-color: rgb(249, 243, 255);
    height: 75vh;
    margin-top: 30vh;
    align-items: center;
    text-align: start;
    justify-content: center;
}
.JoinOurTeam_row3{
    background-color: rgb(213, 252, 236);
    height: 75vh;
    margin-top: 30vh;
    align-items: center;
    text-align: start;
    justify-content: center;
}
.JoinOurTeam_row4{
    background-color: rgb(205, 207, 250);
    height: 75vh;
    margin-top: 30vh;
    align-items: center;
    text-align: start;
    justify-content: center;
}

.JoinOurTeam_Button {
    background-color: rgb(255, 73, 37);
    color: white;
    border-radius: 0px 15px 15px 15px;
    font-size: 15px;
    padding: 10px 20px 10px 20px;
    border-color: transparent;
    font-family: poppins, sans-serif;
}
.JoinOurTeam_image{
    margin-top: 20px;
    height: 55vh;
    border-radius: 20px;
}