.webTech_sup {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;

}
.webTech_sup h1{
    color: rgb(215, 255, 246);
    font-size: 25vh ;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 
}
.webTech_sup p {
    position: absolute;
    color: rgb(51, 130, 89);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
.webimg_content_tools img{
    height: 12vh;
}

/* For screens with a max width of 1024px */
@media only screen and (max-width: 1024px) {
    .webTech_sup {
        height: 20vh;
        width: 28vh;
        margin: 15px;
    }

    .webTech_sup h1 {
        font-size: 20vh;
    }

    .webTech_sup p {
        font-size: 35px;
    }

    .webimg_content_tools img {
        height: 10vh;
    }
}

/* For screens with a max width of 769px */
@media only screen and (max-width: 769px) {
    .webTech_sup {
        height: 18vh;
        width: 25vh;
        margin: 10px;
    }

    .webTech_sup h1 {
        font-size: 10vh ;
    }

    .webTech_sup p {
        font-size: 30px;
    }

    .webimg_content_tools img {
        height: 8vh;
    }
}
/* For screens with a max width of 430px */
@media only screen and (max-width: 430px) {
    .webTech_sup {
        height: 15vh;
        width: 20vh;
        margin: 8px;
    }

    .webTech_sup h1 {
        font-size: 7vh !important;
    }

    .webTech_sup p {
        font-size: 25px;
    }

    .webimg_content_tools img {
        height: 6vh;
    }
}

