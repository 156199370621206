.TSService_Content_01 {
  background-color: rgb(248, 243, 237);
  display: flex;
  align-items: center;
  height: 120vh;
}
.TSServices_content01 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: oblique 40deg;
}

.TSicon_li i {
  color: rgb(255, 123, 0);
}

.TSicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.TSServices_content01 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
  color: rgb(255, 123, 0);

}

.TSImage_service01 img {
  padding-top: 80px;
  width: 70vh;
  height: 100vh;
}

.TSService_Content_02 {
  background-color: rgb(241, 241, 241);
  display: flex;
  align-items: center;
  height: 120vh;
}
.TSServices_content02 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 15px;
  margin-bottom: 10px;
  font-family: oblique 40deg;
}

.TSicon_li i {
  color: rgb(255, 123, 0);
}
.TSicon_li{
  list-style: none;
}

.TSicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.TSServices_content02 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
  color: rgb(255, 123, 0);

}

.TSImage_service02 img {
  padding-top: -30px;
  width: 80vh;
  height: 100vh;
}
/* Mobile query for screens with a width of 1024px or less */
@media (max-width: 1024px) {
  .TSService_Content_01, .TSService_Content_02 {
    height: auto; /* Adjust height for better fit on smaller screens */
    padding: 20px; /* Add padding for better spacing */
  }
  
  .TSServices_content01 p, .TSServices_content02 p {
    font-size: 16px; /* Adjust font size for readability on smaller screens */
    margin-bottom: 15px; /* Reduce margin for better fit */
  }
  
  .TSServices_content01 h3, .TSServices_content02 h3 {
    font-size: 24px; /* Adjust heading size for better fit */
    padding-top: 15px; /* Adjust padding for better spacing */
  }
  
  .TSImage_service01 img, .TSImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    padding-top: 20px; /* Adjust padding for better spacing */
  }
  
  .col-lg-7, .col-lg-5, .col-lg-4 {
    width: 100%; /* Stack columns vertically on smaller screens */
    padding: 0; /* Remove padding for better fit */
  }
  
  .TSService_Content_01, .TSService_Content_02 {
    text-align: center; /* Center-align text for better appearance */
  }
  
  .TSicon_li li {
    font-size: 14px; /* Adjust font size for better fit on smaller screens */
  }
}
/* Mobile query for screens with a width of 769px or less */
@media (max-width: 769px) {
  .TSService_Content_01, .TSService_Content_02 {
    height: auto; /* Adjust height for better fit on smaller screens */
    padding: 15px; /* Reduce padding for better spacing */
  }
  
  .TSServices_content01 p, .TSServices_content02 p {
    font-size: 14px; /* Reduce font size for readability on smaller screens */
    margin-bottom: 10px; /* Further reduce margin for better fit */
  }
  
  .TSServices_content01 h3, .TSServices_content02 h3 {
    font-size: 20px; /* Adjust heading size for better fit */
    padding-top: 10px; /* Reduce padding for better spacing */
  }
  
  .TSImage_service01 img, .TSImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    padding-top: 10px; /* Adjust padding for better spacing */
  }
  
  .col-lg-7, .col-lg-5, .col-lg-4 {
    width: 100%; /* Stack columns vertically on smaller screens */
    padding: 0; /* Remove padding for better fit */
  }
  
  .TSService_Content_01, .TSService_Content_02 {
    text-align: center; /* Center-align text for better appearance */
  }
  
  .TSicon_li li {
    font-size: 12px; /* Further adjust font size for smaller screens */
  }
}
/* Mobile query for screens with a width of 430px or less */
@media (max-width: 430px) {
  .TSService_Content_01, .TSService_Content_02 {
    height: auto; /* Adjust height for better fit on smaller screens */
    padding: 10px; /* Further reduce padding for better spacing */
  }
  
  .TSServices_content01 p, .TSServices_content02 p {
    font-size: 12px; /* Reduce font size for readability on smaller screens */
    margin-bottom: 8px; /* Reduce margin for better fit */
  }
  
  .TSServices_content01 h3, .TSServices_content02 h3 {
    font-size: 18px; /* Adjust heading size for better fit */
    padding-top: 8px; /* Reduce padding for better spacing */
  }
  
  .TSImage_service01 img, .TSImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    padding-top: 5px; /* Adjust padding for better spacing */
  }
  
  .col-lg-7, .col-lg-5, .col-lg-4 {
    width: 100%; /* Stack columns vertically on smaller screens */
    padding: 0; /* Remove padding for better fit */
  }
  }