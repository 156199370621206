.overall_Content {
  background-image: linear-gradient(to bottom right, rgb(254,191,16), rgb(243, 148, 5));
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  text-align: center;
  padding: 20px 0; /* Added padding for smaller screens */
}

.image_ooo1 img {
  max-width: 100%;
  height: 500px;
}

.Content_item1 h1 {
  font-size: 6vw;
  color:white; /* Reduced font size for medium screens */
}

.Content_item1 h1 span {
  color: black;
}

.Content_item1 ul {
  color: white;
  font-size: 1.25rem; /* Reduced font size for better fit */
  padding-left: 0; /* Removed padding to prevent overflow */
}

.color_round1,
.color_round2 {
  height: 20vh;
  width: 20vh; /* Reduced size for smaller screens */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color_round1 {
  background-color: rgb(254,214,3);
}

.color_round2 {
  background-color: rgb(239,113,121);
}

.color_round1 h1,
.color_round2 h1 {
  font-size: 4vw; /* Reduced font size for circles */
}

.color_roundh4 h5 {
  font-size: 1rem;
  padding: 10px;
}

@media (max-width: 768px) {
  .overall_Content {
    flex-direction: column;
    text-align: center;
    padding: 15px 0;
  }

  .Content_item1 h1 {
    font-size: 7vw; /* Adjusted font size for 768px */
  }

  .color_round1,
  .color_round2 {
    height: 13vh;
    width: 13vh; /* Adjusted size for 768px */
  }

  .color_round1 h1,
  .color_round2 h1 {
    font-size: 5vw;
  }
  
  .color_roundh4 h5 {
    font-size: 0.95rem; /* Reduced font size for text blocks */
  }
}

@media (max-width: 430px) {
  .overall_Content {
    flex-direction: column;
    padding: 10px 0;
  }

  .Content_item1 h1 {
    font-size: 9vw;
  }

  .color_round1,
  .color_round2 {
    height: 10vh;
    width: 10vh; /* Adjusted size for small screens */
  }

  .color_round1 h1,
  .color_round2 h1 {
    font-size: 6vw;
  }

  .color_roundh4 h5 {
    font-size: 0.85rem; /* Adjusted for small screens */
  }
}
