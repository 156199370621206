.mobService_Content_01{
    background-color: rgb(248,243,237) ;
    display: flex;
    align-items: center;
    height: 120vh;
}
.mobServices_content01 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.mobicon_li i {
    color: rgb(255, 123, 0);
}

.mobicon_li {
    list-style-type: none; /* Removes the bullet points */
    padding: 0;
    margin: 0;
}

.mobicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.mobServices_content01 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);
}

.mobImage_service01 img{
    padding-top: 80px;
    width: 70vh;
    height: 100vh;
    border-radius: 200px 0px 0px 0px;
}


.mobService_Content_02{
    background-color: rgb(241,241,241);
    display: flex;
    align-items: center;
    height: 120vh;

}
.mobServices_content02 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.mobApp1 h1{
    color: lightslategray;

}

.mobicon_li i {
    color: rgb(255, 123, 0);
}

.mobicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.mobServices_content02 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);

  
}

.mobImage_service02 img{
    padding-top: 40px;
    width: 80vh;
    height: 100vh;
    border-radius: 0px 200px 0px 0px;
}

@media (max-width: 1024px) {
    .mobService_Content_01,
    .mobService_Content_02 {
      flex-direction: row;
      align-items: flex-start;
      height: auto;
    }
  
    .mobServices_content01,
    .mobServices_content02 {
      padding: 20px;
      text-align: center;
    }
  
    .mobImage_service01 img,
    .mobImage_service02 img {
      width: 100%;
      height: auto;
      padding-top: 20px;
      border-radius: 0;
    }
  
    .mobApp1 {
      text-align: center;
      font-size: 24px;
    }
  
    .mobServices_content01 h3,
    .mobServices_content02 h3 {
      font-size: 22px;
    }
  
    .mobicon_li li {
      font-size: 16px;
    }
  }
  
  @media (max-width: 769px) {
    .mobService_Content_01,
    .mobService_Content_02 {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }
  
    .mobServices_content01,
    .mobServices_content02 {
      padding: 15px;
      text-align: center;
    }
  
    .mobImage_service01 img,
    .mobImage_service02 img {
      width: 100%;
      height: auto;
      padding-top: 15px;
      border-radius: 0;
    }
  
    .mobApp1 {
      text-align: center;
      font-size: 22px;
    }
  
    .mobServices_content01 h3,
    .mobServices_content02 h3 {
      font-size: 20px;
    }
  
    .mobicon_li li {
      font-size: 14px;
    }
  }
  @media (max-width: 430px) {
    .mobService_Content_01,
    .mobService_Content_02 {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  
    .mobServices_content01,
    .mobServices_content02 {
      padding: 10px;
      text-align: center;
    }
  
    .mobImage_service01 img,
    .mobImage_service02 img {
      width: 100%;
      height: auto;
      padding-top: 10px;
      border-radius: 0;
    }
  
    .mobApp1 {
      text-align: center;
      font-size: 20px;
    }
  
    .mobServices_content01 h3,
    .mobServices_content02 h3 {
      font-size: 18px;
    }
  
    .mobicon_li li {
      font-size: 12px;
    }
  }
    