.Value_all {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.Value_all11 h1 {
    color: orangered;
    font-weight: 700;
    font-size: 3rem;
}

.Value_all11 span {
    color: black;
    font-size: 2rem;
    margin-top: 10px;
    font-weight: 700;
}

.Technology_User img {
    width: 100%;
    max-width: 150px;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin:10px 10px;
}

.Technology_User img:hover,
.Technology_User img:active {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
