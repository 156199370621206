.CLTech_sup {
  height: 25vh;
  width: 30vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px;
}
.CLTech_sup h1 {
  color: rgb(215, 255, 246);
  font-size: 25vh;
  border-color: tomato;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 0;
}
.CLTech_sup p {
  position: absolute;
  color: rgb(51, 130, 89);
  font-size: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0; /* Remove default margin */
  font-weight: 500;
}
.CLimg_content_tools img {
  height: 12vh;
}
@media (max-width: 1024px) {
  .CLTech_sup {
      height: 20vh; /* Adjust height */
      width: 50vh;  /* Adjust width */
      margin: 10px; /* Adjust margin */
  }
  .CLTech_sup h1 {
      font-size: 20vh; /* Adjust font size */
  }
  .CLTech_sup p {
      font-size: 30px; /* Adjust font size */
  }
  .CLimg_content_tools img {
      height: 10vh; /* Adjust image size */
  }
}
@media (max-width: 769px) {
  .CLTech_sup {
      height: 15vh; /* Adjust height */
      width: 40vh;  /* Adjust width */
      margin: 5px;  /* Adjust margin */
  }
  .CLTech_sup h1 {
      font-size: 11vh; /* Adjust font size */
  }
  .CLTech_sup p {
      font-size: 24px; /* Adjust font size */
  }
  .CLimg_content_tools img {
      height: 8vh; /* Adjust image size */
  }
}
@media (max-width: 430px) {
  .CLTech_sup {
      height: 10vh; /* Adjust height */
      width: 35vh;  /* Adjust width */
      margin: 2px;  /* Adjust margin */
  }
  .CLTech_sup h1 {
      font-size: 6vh; /* Adjust font size */
  }
  .CLTech_sup p {
      font-size: 18px; /* Adjust font size */
  }
  .CLimg_content_tools img {
      height: 6vh; /* Adjust image size */
  }
}
