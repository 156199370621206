.DGxcontainer_blog{
  height: 120vh;
  width: 100%;
  background-color: rgb(239,247,245);
}
.DGxBlog_Studies {
  height: 25vh;
  width: 10vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.DGxBlog_Studies h1 {
  color: rgb(231,238,237);
  font-size: 15vh;
  border-color: tomato;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 0;
}

.DGxBlog_Studies p {
  position: absolute;
  color: rgb(14,43,63);
  font-size: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-weight: 500;
}

.DGxBlog_1 {
  margin: 10px 0;
}

.DGxzoom-img {
  overflow: hidden;
}

.DGxzoom-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s ease;
}

.DGxzoom-img img:hover {
  transform: scale(1.2);
}

.DGxBlog_1_sub {
  align-items: center;
  text-align: start;
  display: flex;
}

.DGxBlog_1_sub p {
  padding-left: 100px;
}
@media (max-width: 1024px) {
  .DGxcontainer_blog {
    height: auto; /* Adjust height to fit content */
    padding: 20px; /* Add padding for spacing */
  }

  .DGxBlog_Studies {
    height: auto; /* Adjust height */
    width: 100%; /* Ensure full width */
    text-align: center; /* Center align text */
  }

  .DGxBlog_Studies h1 {
    font-size: 12vh; /* Reduce font size */
    margin-bottom: 10px; /* Add margin for spacing */
  }

  .DGxBlog_Studies p {
    font-size: 30px; /* Reduce font size */
    padding-top: 10px; /* Add padding for spacing */
  }

  .DGxBlog_1 {
    margin: 15px 0; /* Adjust margin for spacing */
  }

  .DGxBlog_1 h4 {
    font-size: 20px; /* Reduce font size for blog titles */
  }

  .DGxzoom-img img {
    height: auto; /* Maintain aspect ratio */
    width: 100%; /* Ensure full width */
  }

  .DGxBlog_1_sub {
    flex-direction: row; /* Stack elements vertically */
    text-align: center; /* Center align text */
    padding-top: 10px; /* Add padding for spacing */
  }

  .DGxBlog_1_sub p {
    padding-left: 0; /* Remove padding */
    font-size: 16px; /* Reduce font size for dates */
  }

  .DGxBlog_1_sub span {
    margin-bottom: 10px; /* Add margin for spacing */
  }
}
@media (max-width: 769px) {
  .DGxcontainer_blog {
    height: auto; /* Adjust height to fit content */
    padding: 15px; /* Reduce padding for spacing */
  }

  .DGxBlog_Studies {
    height: auto; /* Adjust height */
    width: 100%; /* Ensure full width */
    text-align: center; /* Center align text */
  }

  .DGxBlog_Studies h1 {
    font-size: 10vh; /* Further reduce font size */
    margin-bottom: 8px; /* Adjust margin for spacing */
  }

  .DGxBlog_Studies p {
    font-size: 28px; /* Further reduce font size */
    padding-top: 8px; /* Adjust padding for spacing */
  }

  .DGxBlog_1 {
    margin: 12px 0; /* Adjust margin for spacing */
  }

  .DGxBlog_1 h4 {
    font-size: 18px; /* Further reduce font size for blog titles */
  }

  .DGxzoom-img img {
    height: auto; /* Maintain aspect ratio */
    width: 100%; /* Ensure full width */
  }

  .DGxBlog_1_sub {
    flex-direction: column; /* Stack elements vertically */
    text-align: center; /* Center align text */
    padding-top: 8px; /* Adjust padding for spacing */
  }

  .DGxBlog_1_sub p {
    padding-left: 0; /* Remove padding */
    font-size: 14px; /* Further reduce font size for dates */
  }

  .DGxBlog_1_sub span {
    margin-bottom: 8px; /* Adjust margin for spacing */
  }
}
@media (max-width: 430px) {
  .DGxcontainer_blog {
    height: auto; /* Adjust height to fit content */
    padding: 10px; /* Further reduce padding for spacing */
  }

  .DGxBlog_Studies {
    height: auto; /* Adjust height */
    width: 100%; /* Ensure full width */
    text-align: center; /* Center align text */
  }

  .DGxBlog_Studies h1 {
    font-size: 8vh; /* Further reduce font size */
    margin-bottom: 6px; /* Adjust margin for spacing */
  }

  .DGxBlog_Studies p {
    font-size: 24px; /* Further reduce font size */
    padding-top: 6px; /* Adjust padding for spacing */
  }

  .DGxBlog_1 {
    margin: 10px 0; /* Further adjust margin for spacing */
  }

  .DGxBlog_1 h4 {
    font-size: 16px; /* Further reduce font size for blog titles */
  }

  .DGxzoom-img img {
    height: auto; /* Maintain aspect ratio */
    width: 100%; /* Ensure full width */
  }

  .DGxBlog_1_sub {
    flex-direction: column; /* Stack elements vertically */
    text-align: center; /* Center align text */
    padding-top: 6px; /* Adjust padding for spacing */
  }

  .DGxBlog_1_sub p {
    padding-left: 0; /* Remove padding */
    font-size: 12px; /* Further reduce font size for dates */
  }

  .DGxBlog_1_sub span {
    margin-bottom: 6px; /* Adjust margin for spacing */
  }
}
  