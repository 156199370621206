.Header_Overall0001 {
  background-color: white;
  height: auto;
  position: relative;
  z-index: 1000; 
}

.Contantbox {
  display: flex; 
  align-items: center;
  justify-content: space-between;
}

.Contantbox.open {
  display: flex;
}

.rblock {
  position: relative;
}

.ablock {
  position: absolute;
  z-index: 1100; 
  top: 50px;
  right: 0;
}

.vertical-menu {
  background-color: white;
  font-size: large;
  border-radius: 5px;
  border: 0.5px solid #4f4f4f;
  width: 100px;
  z-index: 1200 !important;
}

.vertical-menu a {
  font-family: sans-serif;
  font-size: 15px;
  text-align: center;
}

@media (max-width: 991px) {
  .Contantbox {
    display: none;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1100; /* Ensure Contantbox remains above page content */
  }

  .Contantbox.open {
    display: flex;
  }
}

.link1 a {
  color: black;
  text-decoration: none;
  padding: 5px 10px;
  font-family: 'Poppins', sans-serif;
}

.link1 a:hover {
  color: orangered;
}

.btn02 {
  background-color: rgb(255, 73, 37);
  color: white;
  border-radius: 0px 15px 15px 15px;
  font-size: 10px;
  padding: 15px 20px;
  border-color: transparent;
  font-family: 'Poppins', sans-serif;
  margin: 5px 10px 0 20px;
}

.menu-icon {
  cursor: pointer;
  height: 50px;
  width: 50px;
  font-size: 30px;
  color: black;
}

/* Adjust the header layout for smaller screens */
@media (max-width: 768px) {
  .Header_Overall0001 {
    height: auto;
    padding: 10px;
  }

  .imgaelogo img {
    height: 40px;
    margin-right: 15px;
  }

  .btn02 {
    font-size: 14px;
    padding: 8px 15px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 430px) {
  .Contantbox {
    display: none;
  }

  .smallmenu {
    display: flex;
  }

  .one {
    order: 1;
  }

  .two {
    order: 2 !important;
  }

  .three {
    order: 3 !important;
  }
}

.dropdown-toggle {
  color: black;
  padding: 5px 10px;
  font-family: 'Poppins', sans-serif;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
  color: orangered;
}

.dzvisible {
  z-index: 1200 !important; /* Higher z-index to resolve overlap issue */
}
