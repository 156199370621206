.player_wrapper01 {
    position: relative;
    padding-top: 56.25%; 
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  
  .react_player01 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 768px) {
    
    .player_wrapper01 {
      padding-top: 56.25%; 
    }
  
    .react_player01 {
      width: 100%;
      height: auto; 
    }
  }
  
  @media (max-width: 430px) {
    .player_wrapper01 {
      padding-top: 56.25%; 
    }
  
    .react_player01 {
      width: 100%;
      height: auto; 
    }
  }
  