.MobTech_sup {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;

}
.MobTech_sup h1{
    color: rgb(215, 255, 246);
    font-size: 25vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 
}
.MobTech_sup p {
    position: absolute;
    color: rgb(51, 130, 89);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
.Mobimg_content_tools img{
    height: 12vh;
}
@media only screen and (max-width: 1024px) {
    .MobTech_sup {
        height: 20vh; /* Adjust height for smaller screens */
        width: 80vh;  /* Adjust width to make it more responsive */
        margin: 10px; /* Adjust margin */
    }

    .MobTech_sup h1 {
        font-size: 15vh; /* Reduce font size */
    }

    .MobTech_sup p {
        font-size: 30px; /* Adjust font size for the description */
    }

    .Mobimg_content_tools img {
        height: 10vh; /* Adjust image height */
        margin: 0 10px; /* Add some space between images */
    }

    .Mobimg_content_tools {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}
@media only screen and (max-width: 769px) {
    .MobTech_sup {
        height: 18vh; /* Further reduce height for smaller screens */
        width: 60vh;  /* Adjust width to be more compact */
        margin: 8px;  /* Adjust margin for smaller screens */
    }

    .MobTech_sup h1 {
        font-size: 12vh; /* Further reduce font size */
    }

    .MobTech_sup p {
        font-size: 25px; /* Adjust font size for the description */
    }

    .Mobimg_content_tools img {
        height: 8vh; /* Further adjust image height */
        margin: 0 8px; /* Adjust spacing between images */
    }

    .Mobimg_content_tools {
        display: flex;
        justify-content: center; /* Center images for smaller screens */
        align-items: center;
        flex-wrap: wrap; /* Allow wrapping if needed */
    }
}
@media only screen and (max-width: 430px) {
    .MobTech_sup {
        height: 15vh; /* Reduce height further for smaller screens */
        width: 50vh;  /* Adjust width to be even more compact */
        margin: 5px;  /* Adjust margin for smaller screens */
    }

    .MobTech_sup h1 {
        font-size: 7vh; /* Reduce font size */
    }

    .MobTech_sup p {
        font-size: 20px; /* Adjust font size for the description */
    }

    .Mobimg_content_tools img {
        height: 6vh; /* Further reduce image height */
        margin: 0 5px; /* Adjust spacing between images */
    }

    .Mobimg_content_tools {
        display: flex;
        justify-content: center; /* Center images */
        align-items: center;
        flex-wrap: wrap; /* Allow wrapping for smaller screens */
    }
}
