.DGTech_sup {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;

}
.DGTech_sup h1{
    color: rgb(215, 255, 246);
    font-size: 25vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 
}
.DGTech_sup p {
    position: absolute;
    color: rgb(51, 130, 89);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
.DGimg_content_tools img{
    height: 12vh;
}

/* Media Query for screens with a width of 1024px */
@media (max-width: 1024px) {
    .DGTech_sup {
        height: 20vh; /* Adjust height */
        width: 50vh;  /* Adjust width */
        margin: 10px; /* Adjust margin */
    }
    .DGTech_sup h1 {
        font-size: 20vh; /* Adjust font size */
    }
    .DGTech_sup p {
        font-size: 30px; /* Adjust font size */
    }
    .DGimg_content_tools img {
        height: 10vh; /* Adjust image size */
    }
}
@media (max-width: 769px) {
    .DGTech_sup {
        height: 15vh; /* Adjust height */
        width: 40vh;  /* Adjust width */
        margin: 5px;  /* Adjust margin */
    }
    .DGTech_sup h1 {
        font-size: 11vh; /* Adjust font size */
    }
    .DGTech_sup p {
        font-size: 24px; /* Adjust font size */
    }
    .DGimg_content_tools img {
        height: 8vh; /* Adjust image size */
    }
}
@media (max-width: 430px) {
    .DGTech_sup {
        height: 12vh; /* Adjust height */
        width: 30vh;  /* Adjust width */
        margin: 2px;  /* Adjust margin */
    }
    .DGTech_sup h1 {
        font-size: 7vh; /* Adjust font size */
    }
    .DGTech_sup p {
        font-size: 15px; /* Adjust font size */
    }
    .DGimg_content_tools img {
        height: 6vh; /* Adjust image size */
    }
}