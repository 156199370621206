.container_CaseStudy {
  background-color: rgb(14, 43, 63);
}

.Case_Studies {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading h1 {
  color: rgb(25, 54, 69);
  position: relative; 
  z-index: 1; 
  font-size: 100px;
  text-align: center;
}

.heading p {
  position: absolute;
  color: rgb(255, 255, 255);
  z-index: 2; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  text-align: center;
}

.img1 {
  border-radius: 10px;
  border: 2px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  height:400px;
  width:100%;
  object-fit: cover;
}

.alll {
  margin:10px;
  position: relative;
  overflow: hidden;
}

.lay {
  position: absolute;
  background-color: rgba(6, 5, 11, 0.6);
  overflow: hidden;
  transition: height 0.5s ease;
  bottom: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  height:0;
  width:90%;
}

.lay h5 {
  text-align: center;
  color: white;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.lay p {
  text-align: center;
  color: white;
}

.alll:hover .lay {
  height: 80%;
  width:90%;
}

.icon_1, .icon_2, .icon_3 {
  color: white;
}

.icon_3 i {
  color: blue;
}

.icon_2 i {
  color: greenyellow;
}

.icon_1 i {
  color: blueviolet;
}
