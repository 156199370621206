.webContent_Development {
    height: 80vh;
    width: 100%;
    background: 
    linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)), 
    url("../../Webapplication/Overview/Assests/1.jpg");    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20vh;
  }

  .webContent_Development h1{
     color: white;
     font-weight: 700;
     padding: 5px;
     font-size: 60px;

  }
  
  .webContent_Development p{
    color: white;
    font-size: 30px;

 }
 
.webButton_Contain {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .webButton_Contain button {
    background-color: #faf9f9;
    color: #786e6e;
    border: none;
    padding: 10px 20px;
    margin: -10px 80px 0px 0px;
    font-size: 26px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .webButton_Contain button:hover {
color: orange;  }

.Overview_content  p{
  color: black;
 font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}


@media screen and (max-width: 1024px) {
  .webContent_Development {
    padding-top: 20vh;
  }

  .webContent_Development h1 {
    font-size: 40px;
  }

  .webContent_Development p {
    font-size: 20px;
  }

  .webButton_Contain {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    justify-content: center; /* Center the buttons vertically */
  align-items: center; /* Center the buttons horizontally */
  }

  .webButton_Contain button {
    margin: 10px 0; /* Adjust margin to create space between buttons in column */
   padding: 20px ;
   font-size: 30px;

  }

  .webOverview_content p {
    font-size: 30px;
    margin-left: 10px;
    display: block;
  }
  .webOverview_content {
    padding: 10px 20px; /* Add some padding for spacing around content */
  }
}

@media screen and (max-width: 769px) {
  .webContent_Development {
    padding-top: 18vh; /* Adjust the padding for smaller screens */
  }

  .webContent_Development h1 {
    font-size: 30px; /* Slightly smaller font size */
  }

  .webContent_Development p {
    font-size: 18px; /* Smaller font size for better readability */
  }

  .webButton_Contain {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center the buttons */
    justify-content: center; /* Center the container itself */
    margin-top: 5px;
  }

  .webButton_Contain button {
    margin: 8px 0; /* Reduce margin and center the buttons */
    padding: 6px 12px; /* Adjust padding for smaller screens */
    font-size: 20px; /* Slightly smaller font size */
  }

  .webOverview_content p {
    font-size: 18px;
    margin-left: 5px; /* Adjust margin */
    display: block;
    width: 100%;
  }

  .webOverview_content {
    padding: 8px 15px; /* Adjust padding */
  }
}

@media screen and (max-width: 430px) {
  .webContent_Development {
    padding-top: 18vh; /* Reduce padding for very small screens */
  }

  .webContent_Development h1 {
    font-size: 20px; /* Smaller font size */
  }

  .webContent_Development p {
    font-size: 12px; /* Smaller font size for readability */
  }

  .webButton_Contain {
    display: flex;
    flex-direction: column; /* Keep buttons stacked vertically */
    align-items: center; /* Center the buttons */
    justify-content: center; /* Center the container itself */
    margin-top: 5px;
  }

  .webButton_Contain button {
    margin: 6px 0; /* Further reduce margin */
    padding: 5px 10px; /* Adjust padding for small screens */
    font-size: 18px; /* Smaller font size */
  }

  .webOverview_content p {
    font-size: 16px;
    margin-left: 3px; /* Adjust margin for small screens */
    display: block;
    width: 100%;
  }

  .webOverview_content {
    padding: 5px 10px; /* Further reduce padding */
  }
}


