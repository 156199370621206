.TSContent_Development4 {
  height: 80vh;
  width: 100%;
  background: linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)),
    url("../../TestingServices/TestingOverview/Assets/QA1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 20vh;
}

.TSContent_Development4 h1 {
  color: white;
  font-weight: 700;
  padding: 5px;
  font-size: 60px;
}

.TSContent_Development4 p {
  color: white;
  font-size: 30px;
}

.TSButton_Contain {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.TSButton_Contain button {
  background-color: #faf9f9;
  color: #786e6e;
  border: none;
  padding: 10px 20px;
  margin: -10px 80px 0px 0px;
  font-size: 26px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.TSButton_Contain button:hover {
  color: orange;
}

.TSOverview_content p {
  color: black;
  font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .TSContent_Development4 {
    padding-top: 10vh; /* Adjust padding for smaller screens */
  }

  .TSContent_Development4 h1 {
    font-size: 40px; /* Adjust font size for smaller screens */
  }

  .TSContent_Development4 p {
    font-size: 24px; /* Adjust font size for smaller screens */
  }

  .TSButton_Contain {
    margin-top: 10px;
    flex-direction: row; /* Stack buttons vertically */
  }

  .TSButton_Contain button {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin: 10px 0; /* Adjust margin for better spacing on smaller screens */
  }

  .TSOverview_content p {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin-left: 0; /* Remove margin for better alignment */
    text-align: center; /* Center align text for better readability */
    display: block;
  }
}
/* Mobile query for screens with width of 769px or less */
@media (max-width: 769px) {
  .TSContent_Development4 {
    padding-top: 5vh; /* Further reduced padding for smaller screens */
  }

  .TSContent_Development4 h1 {
    font-size: 30px; /* Further reduced font size for heading */
  }

  .TSContent_Development4 p {
    font-size: 20px; /* Further reduced font size for paragraphs */
  }

  .TSButton_Contain {
    margin-top: 5px;
    flex-direction: column; /* Ensure buttons stack vertically */
  }

  .TSButton_Contain button {
    font-size: 18px; /* Adjusted font size for buttons */
    margin: 8px 0; /* Adjust margin for better spacing on smaller screens */
  }

  .TSOverview_content p {
    font-size: 16px; /* Adjusted font size for paragraphs */
    margin-left: 0; /* Remove margin for better alignment */
    text-align: center; /* Center align text for better readability */
  }
}
/* Mobile query for screens with width of 430px or less */
@media (max-width: 430px) {
  .TSContent_Development4 {
    padding-top: 2vh; /* Further reduced padding for very small screens */
  }

  .TSContent_Development4 h1 {
    font-size: 24px; /* Further reduced font size for heading */
  }

  .TSContent_Development4 p {
    font-size: 18px; /* Further reduced font size for paragraphs */
  }

  .TSButton_Contain {
    margin-top: 5px;
    flex-direction: column; /* Ensure buttons stack vertically */
    align-items: center; /* Center align buttons */
  }

  .TSButton_Contain button {
    font-size: 16px; /* Adjusted font size for buttons */
    margin: 6px 0; /* Adjust margin for better spacing on very small screens */
    width: 90%; /* Ensure buttons take up most of the screen width */
  }

  .TSOverview_content p {
    font-size: 14px; /* Adjusted font size for paragraphs */
    margin-left: 0; /* Remove margin for better alignment */
    text-align: center; /* Center align text for better readability */
  }
}

