/* BankConnect.css */
.container {
    padding: 0;
    margin-bottom: 20px;
  }
  
  .BankConnect_contentimg {
    position: relative;
    text-align: center;
    overflow: hidden;
  }
  
  .BankConnect_contentimg img {
    width: 100%;
    display: block;
  }
  
  .BankConnect_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
  }
  
  .BankConnect_content h1 {
    margin: 0;
    font-size: 2.5rem; /* Base font size for large screens */
  }
  
  .Button_ContactUs {
    margin-top: 1rem;
  }
  
  .Button_ContactUs button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    background-color: #007bff; /* Adjust button color as needed */
    color: #fff;
    cursor: pointer;
    border-radius: 0.25rem; /* Rounded corners */
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .BankConnect_content h1 {
      font-size: 2rem; /* Adjust for medium screens */
    }
  
    .Button_ContactUs button {
      font-size: 0.875rem;
      padding: 0.5rem;
    }
  }
  
  @media (max-width: 430px) {
    .BankConnect_content h1 {
      font-size: 1.5rem; /* Adjust for small screens */
    }
  
    .Button_ContactUs button {
      font-size: 0.75rem;
      padding: 0.4rem;
    }

    .BankConnect_contentimg img {
        width: 100%;
        height:600px;
        display: block;
      }
    
  }
  