@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

*{
  font-family: "Poppins", sans-serif;
}

  .BankServices_Content h3{
    color: rgb(204, 107, 47);
  }

  .BankServices_Content{
    font-family: "Poppins", sans-serif;
  }

.mobile1 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/1.jpeg");
  border-radius: 10px;
    height: 500px;
}

.mobile_img2 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/2.jpeg");
    border-radius: 10px;
    height: 500px;
}

.mobile_img3 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/3%20(1).jpeg");
    border-radius: 10px;
    height: 500px;
}

.mobile_img4 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/4.jpeg");
    border-radius: 10px;
    height: 500px;
}

.mobile_img5 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/5.jpeg");
    border-radius: 10px;
    height: 500px;
}

.Line_color {
  color: orange;
}

.txt_01 {
  color: white;
  font-size: 40px;
  text-align: center;
}

.txt_02 {
  color: white;
  font-size: 20px;
  text-align: center;
}

.BankServices_Content p {
  margin: 29px 0;
  font-size: larger;
  letter-spacing: 3px;
}
