.DAService_Content_01{
    background-color: rgb(248,243,237) ;
    display: flex;
    align-items: center;
    height: 120vh;
}
.DAServices_content01 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.DAicon_li i {
    color: rgb(255, 123, 0);
}

.DAicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.DAServices_content01 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);

  
}

.DAImage_service01 img{
    padding-top: 80px;
    width: 70vh;
    height: 100vh;
    border-radius: 200px 0px 0px 0px;
}


.DAService_Content_02{
    background-color: rgb(241,241,241);
    display: flex;
    align-items: center;
    height: 120vh;
    
}
.DAServices_content02 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.DAicon_li i {
    color: rgb(255, 123, 0);
}

.DAicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}

.DAicon_li{
    list-style: none;
}
.DAServices_content02 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);

  
}

.DAImage_service02 img{
    padding-top: 40px;
    width: 80vh;
    height: 100vh;
    border-radius: 0px 200px 0px 0px;
}

@media (max-width: 1024px) {
    .DAService_Content_01, .DAService_Content_02 {
      flex-direction: row; /* Stack content vertically */
      height: auto; /* Adjust height to fit content */
      padding: 20px; /* Add padding for spacing */
    }
  
    .DAServices_content01, .DAServices_content02 {
      width: 100%; /* Ensure text content takes full width */
      text-align: center; /* Center align text */
    }
  
    .DAImage_service01 img, .DAImage_service02 img {
      width: 90%; /* Adjust image width */
      height: 100vh; /* Maintain aspect ratio */
      padding-top: 20px; /* Adjust padding */
      border-radius: 20px; /* Adjust border-radius */
      margin: 0 auto; /* Center image */
    }
  
    .DAServices_content01 h3, .DAServices_content02 h3 {
      font-size: 28px; /* Adjust font size */
    }
  
    .DAServices_content01 p, .DAServices_content02 p {
      font-size: 16px; /* Adjust font size */
    }
  
    .DAicon_li li {
      font-size: 16px; /* Adjust font size */
      padding: 5px; /* Adjust padding */
    }
  }
  @media (max-width: 769px) {
    .DAService_Content_01, .DAService_Content_02 {
      flex-direction: column; /* Stack content vertically */
      height: auto; /* Adjust height to fit content */
      padding: 15px; /* Reduce padding for spacing */
    }
  
    .DAServices_content01, .DAServices_content02 {
      width: 100%; /* Ensure text content takes full width */
      text-align: center; /* Center align text */
    }
  
    .DAImage_service01 img, .DAImage_service02 img {
      width: 85%; /* Further adjust image width */
      height: auto; /* Maintain aspect ratio */
      padding-top: 15px; /* Reduce padding */
      border-radius: 15px; /* Adjust border-radius */
      margin: 0 auto; /* Center image */
      display: block;
    }
    @media (max-width: 430px) {
        .DAService_Content_01, .DAService_Content_02 {
          flex-direction: column; /* Stack content vertically */
          height: auto; /* Adjust height to fit content */
          padding: 10px; /* Further reduce padding for spacing */
        }
      
        .DAServices_content01, .DAServices_content02 {
          width: 100%; /* Ensure text content takes full width */
          text-align: center; /* Center align text */
        }
      
        .DAImage_service01 img, .DAImage_service02 img {
          width: 80%; /* Further adjust image width */
          height: auto; /* Maintain aspect ratio */
          padding-top: 10px; /* Reduce padding */
          border-radius: 10px; /* Adjust border-radius */
          margin: 0 auto; /* Center image */
        }
      
        .DAServices_content01 h3, .DAServices_content02 h3 {
          font-size: 20px; /* Further reduce font size */
        }
      
        .DAServices_content01 p, .DAServices_content02 p {
          font-size: 12px; /* Further reduce font size */
        }
      
        .DAicon_li li {
          font-size: 12px; /* Further reduce font size */
          padding: 2px; /* Reduce padding */
        }
      }
      
    .DAServices_content01 h3, .DAServices_content02 h3 {
      font-size: 24px; /* Further reduce font size */
    }
  
    .DAServices_content01 p, .DAServices_content02 p {
      font-size: 14px; /* Further reduce font size */
    }
  
    .DAicon_li li {
      font-size: 14px; /* Further reduce font size */
      padding: 3px; /* Reduce padding */
    }
  }
  