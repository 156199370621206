.DAContent_Development4 {
    height: 80vh;
    width: 100%;
    background: 
    linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)), 
    url("../../DataAnalyst/DataOverview/Assests/DataA1.jpg");    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20vh;
  }

  .DAContent_Development4 h1{
     color: white;
     font-weight: 700;
     padding: 5px;
     font-size: 60px;

  }
  
  .DAContent_Development4 p{
    color: white;
    font-size: 30px;

 }
 
.DAButton_Contain {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .DAButton_Contain button {
    background-color: #faf9f9;
    color: #786e6e;
    border: none;
    padding: 10px 20px;
    margin: -10px 80px 0px 0px;
    font-size: 26px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .DAButton_Contain button:hover {
color: orange;  }

.DAOverview_content  p{
  color: black;
 font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .DAContent_Development4 {
    height: 60vh; /* Adjusted height for smaller screens */
    padding-top: 15vh; /* Adjusted padding to center content */
  }

  .DAContent_Development4 h1 {
    font-size: 45px; /* Reduced font size */
  }

  .DAContent_Development4 p {
    font-size: 24px; /* Reduced font size */
  }

  .DAButton_Contain {
    flex-direction: row; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  .DAButton_Contain button {
    margin: 10px 0; /* Add margin between buttons */
    font-size: 28px; /* Reduced font size */
  }

  .DAOverview_content p {
    font-size: 18px; /* Reduced font size */
    margin-left: 10px; /* Adjusted margin */
  }
}
@media (max-width: 760px) {
  .DAContent_Development4 {
    height: 55vh; /* Further adjusted height for smaller screens */
    padding-top: 10vh; /* Adjusted padding to center content */
  }

  .DAContent_Development4 h1 {
    font-size: 40px; /* Further reduced font size */
  }

  .DAContent_Development4 p {
    font-size: 22px; /* Further reduced font size */
  }

  .DAButton_Contain {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  .DAButton_Contain button {
    margin: 8px 0; /* Adjusted margin between buttons */
    font-size: 20px; /* Further reduced font size */
  }

  .DAOverview_content p {
    font-size: 16px; /* Further reduced font size */
    margin-left: 5px; /* Adjusted margin */
  }
}
@media (max-width: 430px) {
  .DAContent_Development4 {
    height: 50vh; /* Adjusted height for smaller screens */
    padding-top: 8vh; /* Adjusted padding to center content */
  }

  .DAContent_Development4 h1 {
    font-size: 35px; /* Further reduced font size */
  }

  .DAContent_Development4 p {
    font-size: 18px; /* Further reduced font size */
  }

  .DAButton_Contain {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  .DAButton_Contain button {
    margin: 6px 0; /* Adjusted margin between buttons */
    font-size: 18px; /* Further reduced font size */
  }

  .DAOverview_content p {
    font-size: 14px; /* Further reduced font size */
    margin-left: 3px; /* Adjusted margin */
  }
}

