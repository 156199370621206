.DGService_Content_01 {
  background-color: rgb(248, 243, 237);
  display: flex;
  align-items: center;
  height: 120vh;
}
.DGServices_content01 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: oblique 40deg;
}

.DGicon_li i {
  color: rgb(255, 123, 0);
}

.DGicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.DGServices_content01 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
  color: rgb(255, 123, 0);

}

.DGImage_service01 img {
  padding-top: 80px;
  width: 70vh;
  height: 100vh;
}

.DGService_Content_02 {
  background-color: rgb(241, 241, 241);
  display: flex;
  align-items: center;
  height: 120vh;
}
.DGServices_content02 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: oblique 40deg;
}

.DGicon_li i {
  color: rgb(255, 123, 0);
}

.DGicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.DGicon_li{
  list-style: none;
}
.DGServices_content02 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
  color: rgb(255, 123, 0);

}

.DGImage_service02 img {
  padding-top: 40px;
  width: 80vh;
  height: 100vh;
}
@media only screen and (max-width: 1024px) {
  .DGService_Content_01, .DGService_Content_02 {
    flex-direction: row;
    height: auto;
    padding: 20px;
  }

  .DGServices_content01, .DGServices_content02 {
    text-align: center;
    padding: 20px;
  }

  .DGImage_service01 img, .DGImage_service02 img {
    width: 100%;
    height: 100vh;
    padding-top: 20px;
  }

  .DGicon_li {
    padding-left: 0;
    text-align: center;
  }

  .DGServices_content01 h3, .DGServices_content02 h3 {
    font-size: 24px;
    padding-top: 10px;
  }

  .DGServices_content01 p, .DGServices_content02 p {
    font-size: 16px;
    padding: 10px 0;
  }
}
@media only screen and (max-width: 769px) {
  .DGService_Content_01, .DGService_Content_02 {
    flex-direction: column;
    height: auto;
    padding: 15px;
  }

  .DGServices_content01, .DGServices_content02 {
    text-align: center;
    padding: 15px;
  }

  .DGImage_service01 img, .DGImage_service02 img {
    width: 100%;
    height: auto;
    padding-top: 15px;
  }

  .DGicon_li {
    padding-left: 0;
    text-align: center;
  }

  .DGServices_content01 h3, .DGServices_content02 h3 {
    font-size: 22px;
    padding-top: 8px;
  }

  .DGServices_content01 p, .DGServices_content02 p {
    font-size: 14px;
    padding: 8px 0;
  }
}
@media only screen and (max-width: 430px) {
  .DGService_Content_01, .DGService_Content_02 {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .DGServices_content01, .DGServices_content02 {
    text-align: center;
    padding: 10px;
  }

  .DGImage_service01 img, .DGImage_service02 img {
    width: 100%;
    height: auto;
    padding-top: 10px;
  }

  .DGicon_li {
    padding-left: 0;
    text-align: center;
  }

  .DGServices_content01 h3, .DGServices_content02 h3 {
    font-size: 20px;
    padding-top: 5px;
  }

  .DGServices_content01 p, .DGServices_content02 p {
    font-size: 12px;
    padding: 5px 0;
  }
}
