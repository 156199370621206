.AIService_Content_01{
    background-color: rgb(248,243,237) ;
    display: flex;
    align-items: center;
    height: 120vh;
}
.AIServices_content01 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.AIicon_li i {
    color: rgb(255, 123, 0);
}

.AIicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.AIServices_content01 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);;
  
}

.AIImage_service01 img{
    padding-top: 80px;
    width: 70vh;
    height: 100vh;
}


.AIService_Content_02{
    background-color: rgb(241,241,241);
    display: flex;
    align-items: center;
    height: 120vh;
}
.AIServices_content02 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.AIicon_li i {
    color: rgb(255, 123, 0);

}
.AIicon_li{
    list-style-type: none; 

}

.AIicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.AIServices_content02 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);;

}

.AIImage_service02 img{
    padding-top: 40px;
    width: 80vh;
    height: 100vh;
}

@media (max-width: 1024px) {
    .AIService_Content_01, .AIService_Content_02 {
        flex-direction: row;
        height: auto; /* Adjusted for mobile */
    }

    .col-lg-7, .col-lg-4, .col-lg-5 {
        width: 100%;
        padding: 0; /* Adjusted for mobile */
    }

    .AIImage_service01 img, .AIImage_service02 img {
        width: 100%; /* Ensures images fit within the container */
        height: auto;
        padding-top: 20px; /* Adjust padding for mobile */
    }
    .AIImage_service01 img, .AIImage_service02 img{
        height: 100vh;
    }

    .AIServices_content01, .AIServices_content02 {
        padding: 15px; /* Adjusted padding for mobile */
    }

    .AIicon_li li {
        padding: 5px; /* Adjusted padding for mobile */
        font-size: 14px; /* Adjusted font size for mobile */
    }

    .AIServices_content01 h3, .AIServices_content02 h3 {
        font-size: 20px; /* Adjusted font size for mobile */
    }
}
@media (max-width: 760px) {
    .AIService_Content_01, .AIService_Content_02 {
        flex-direction: column; /* Stack elements vertically */
        height: auto;
    }

    .col-lg-7, .col-lg-4, .col-lg-5 {
        width: 100%;
        padding: 0;
    }

    .AIImage_service01 img, .AIImage_service02 img {
        width: 100%;
        height: auto; /* Ensure images scale properly */
        padding-top: 10px;
    }

    .AIServices_content01, .AIServices_content02 {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .AIicon_li li {
        padding: 3px; /* Further reduce padding */
        font-size: 12px; /* Smaller font size for better fit */
    }

    .AIServices_content01 h3, .AIServices_content02 h3 {
        font-size: 18px; /* Reduce heading size */
    }
}
@media (max-width: 430px) {
    .AIService_Content_01, .AIService_Content_02 {
        flex-direction: column;
        height: auto;
    }

    .col-lg-7, .col-lg-4, .col-lg-5 {
        width: 100%;
        padding: 0;
    }

    .AIImage_service01 img, .AIImage_service02 img {
        width: 100%;
        height: auto;
        padding-top: 5px; /* Reduce padding further */
    }

    .AIServices_content01, .AIServices_content02 {
        padding: 5px; /* Adjust padding for very small screens */
    }

    .AIicon_li li {
        padding: 2px; /* Further reduce padding */
        font-size: 10px; /* Smaller font size for narrow screens */
    }

    .AIServices_content01 h3, .AIServices_content02 h3 {
        font-size: 16px; /* Reduce heading size further */
    }
}
