.AIContent_Development3 {
    height: 80vh;
    width: 100%;
    background: 
    linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)), 
    url("../../AI/AIOverview/Assets/AI1.jpg");    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20vh;
  }

  .AIContent_Development3 h1{
     color: white;
     font-weight: 700;
     padding: 5px;
     font-size: 60px;

  }
  .AIContent_Development3 p{
    color: white;
  }
  
  .AIButton_Contain {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .AIButton_Contain button {
    background-color: #faf9f9;
    color: #786e6e;
    border: none;
    padding: 10px 20px;
    margin: -10px 80px 0px 0px;
    font-size: 26px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .AIButton_Contain button:hover {
color: orange;  }

.AIOverview_content  p{
  color: black;
 font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .AIContent_Development3 {
    height: 60vh; /* Adjust height for better visibility on smaller screens */
    padding-top: 15vh; /* Adjust padding for centering content */
  }

  .AIContent_Development3 h1 {
    font-size: 40px; /* Reduce font size for smaller screens */
    padding: 5px;
  }

  .AIButton_Contain {
    flex-direction: row; /* Stack buttons vertically */
    margin-top: 10px;
    text-align: center;
    justify-content: center;
  }

  .AIButton_Contain button {
    margin: 10px 0; /* Adjust margin to space out buttons */
    font-size: 30px; /* Reduce font size for smaller screens */
  }

  .AIOverview_content p {
    font-size: 16px; /* Adjust font size for better readability */
    margin-left: 10px; /* Adjust margin for alignment */
    margin-right: 10px; /* Add right margin for better spacing */
  }
}
@media (max-width: 769px) {
  .AIContent_Development3 {
    height: 50vh;
    padding-top: 10vh; 
  }

  .AIContent_Development3 h1 {
    font-size: 30px; 
    padding: 5px;
  }

  .AIButton_Contain {
    flex-direction: column; 
    margin-top: 10px;
  }

  .AIButton_Contain button {
    margin: 8px 0; 
    font-size: 18px; 
    text-align: center;
    width: 100%; 
    max-width: 300px; 
  }

  .AIOverview_content p {
    font-size: 14px; 
    margin-left: 10px; 
    margin-right: 10px; /* Maintain margin for spacing */
  }
}
@media (max-width: 430px) {
  .AIContent_Development3 {
    height: 40vh; /* Further adjust height for very small screens */
    padding-top: 5vh; /* Adjust padding for better centering */
  }

  .AIContent_Development3 h1 {
    font-size: 24px; /* Reduce font size for very small screens */
    padding: 5px;
  }
  

  .AIButton_Contain {
    flex-direction: column; /* Ensure buttons stack vertically */
    margin-top: 10px;
    align-items: center; /* Center align the buttons horizontally */
  }

  .AIButton_Contain button {
    margin: 8px 0; /* Adjust margin for better spacing */
    font-size: 16px; /* Further reduce font size */
    width: 100%; /* Make buttons full width to ensure text is centered */
    max-width: 280px; /* Optionally, set a max-width for better control */
    text-align: center; /* Center align text within buttons */
  }

  .AIOverview_content p {
    font-size: 12px; /* Reduce font size for very small screens */
    margin-left: 10px; /* Maintain margin for alignment */
    margin-right: 10px; /* Maintain margin for spacing */
  }
}
