.BMService_Content_01{
    background-color: rgb(248,243,237) ;
    display: flex;
    align-items: center;
    height: 120vh;
}
.BMServices_content01 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.BMicon_li i {
    color: rgb(255, 123, 0);
}

.BMicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.BMServices_content01 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);

  
}

.BMImage_service01 img{
    padding-top: 80px;
    width: 70vh;
    height: 100vh;
}


.BMService_Content_02{
    background-color: rgb(241,241,241);
    display: flex;
    align-items: center;
    height: 120vh;
}
.BMServices_content02 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.BMicon_li i {
    color: rgb(255, 123, 0);
}

.BMicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.BMicon_li{
    list-style: none;
}
.BMServices_content02 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
    color: rgb(255, 123, 0);

  
}

.BMImage_service02 img{
    padding-top: 40px;
    width: 80vh;
    height: 100vh;
}

/* Mobile Query for 1024px width screens */
@media (max-width: 1024px) {
    .BMService_Content_01,
    .BMService_Content_02 {
      flex-direction: row;
      height: auto;
      padding: 20px;
    }
  
    .BMServices_content01, 
    .BMServices_content02 {
      text-align: center;
    }
  
    .BMImage_service01 img,
    .BMImage_service02 img {
      width: 100%;
      height: 50vh;
      padding-top: 20px;
    }
  
    .BMServices_content01 h3,
    .BMServices_content02 h3 {
      padding-top: 10px; 
      font-size: 24px;
    }
  
    .BMServices_content01 p,
    .BMServices_content02 p {
      font-size: 16px;
      padding: 10px;
    }
  
    .BMicon_li li {
      font-size: 16px;
    }
  }
  /* Mobile Query for 769px width screens */
@media (max-width: 769px) {
    .BMService_Content_01,
    .BMService_Content_02 {
      flex-direction: column;
      height: auto;
      padding: 15px;
    }
  
    .BMServices_content01, 
    .BMServices_content02 {
      text-align: center;
      margin: 0 auto;
    }
  
    .BMImage_service01 img,
    .BMImage_service02 img {
      width: 100%;
      height: auto;
      padding-top: 15px;
    }
  
    .BMServices_content01 h3,
    .BMServices_content02 h3 {
      padding-top: 8px; 
      font-size: 22px;
    }
  
    .BMServices_content01 p,
    .BMServices_content02 p {
      font-size: 15px;
      padding: 8px;
    }
  
    .BMicon_li li {
      font-size: 15px;
    }
  }
/* Mobile Query for 430px width screens */
@media (max-width: 430px) {
    .BMService_Content_01,
    .BMService_Content_02 {
      flex-direction: column;
      height: auto;
      padding: 10px;
    }
  
    .BMServices_content01, 
    .BMServices_content02 {
      text-align: center;
      margin: 0 auto;
    }
  
    .BMImage_service01 img,
    .BMImage_service02 img {
      width: 100%;
      height: auto;
      padding-top: 10px;
    }
  
    .BMServices_content01 h3,
    .BMServices_content02 h3 {
      padding-top: 5px; 
      font-size: 20px;
    }
  
    .BMServices_content01 p,
    .BMServices_content02 p {
      font-size: 14px;
      padding: 5px;
    }
  
    .BMicon_li li {
      font-size: 14px;
    }
  }
    