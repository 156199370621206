.webService_Content_01{
    background-color: rgb(248,243,237) ;
    display: flex;
    align-items: center;
    height: 120vh;
}
.webServices_content01 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.webServices_content01 h3{
    color: rgb(228, 119, 79);
}
.webicon_li i {
    color: rgb(255, 123, 0);
}

.webicon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.webServices_content01 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
  
}

.webImage_service01 img{
    padding-top: 80px;
    width: 70vh;
    height: 100vh;
}


.webService_Content_02{
    background-color: rgb(241,241,241);
    display: flex;
    align-items: center;
    height: 120vh;
}
.webServices_content02 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.webServices_content02 h3{
    color: rgb(228, 119, 79); ;
}
.webicon_li i {
    color: rgb(255, 123, 0);
}

.webicon_li li {
    padding: 10px;
    font-family: oblique 40deg;
}
.webicon_li{
  list-style: none;
}
.webServices_content02 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
  
}

.webImage_service02 img{
    padding-top: 40px;
    width: 80vh;
    height: 100vh;
}



    

@media only screen and (max-width: 1024px) {
  .webService_Content_01,
  .webService_Content_02 {
    flex-direction: row;
    height: auto;
    padding: 20px;
  }

  .webServices_content01,
  .webServices_content02 {
    width: 100%;
    text-align: center;
  }

  .webImage_service01 img,
  .webImage_service02 img {
    width: 100%;
    height: auto;
    padding-top: 20px;
  }

  .webServices_content01 h3,
  .webServices_content02 h3 {
    font-size: 20px;
    padding-top: 15px;
  }

  .webServices_content01 p,
  .webServices_content02 p {
    font-size: 14px;
    padding: 10px;
    margin-bottom: 15px;
    margin-left: 80px;
  }

  .webicon_li li {
    font-size: 16px;
  }
}


  @media (max-width: 769px) {
    .webService_Content_01,
    .webService_Content_02 {
      flex-direction: column;
      height: auto;
      align-items: center; 
      
    }
  
    .webImage_service01 img,
    .webImage_service02 img {
      display: block; 
      width: 100%; 
      height: auto; 
      max-width: 500px;
      padding: 20px; 
      max-height: 120vh;
      margin: 0 auto; /* Center the image */
    }
  
    .webServices_content01,
    .webServices_content02 {
      padding: 10px; 
      width: 100%; 
    }
  
    .webServices_content01 h3,
    .webServices_content02 h3 {
      font-size: 20px; 
      text-align: center;
      justify-content: center;
    }
  
    .webServices_content01 p,
    .webServices_content02 p {
      font-size: 12px; 
      text-align: center;
      justify-content: center;
    }
  
    .webicon_li li {
      font-size: 16px; 
      padding: 8px; 
    }
  }
  @media (max-width: 430px) {
  .webService_Content_01,
  .webService_Content_02 {
    flex-direction: column;
    height: auto;
    align-items: center; 
  }

  .webImage_service01 img,
  .webImage_service02 img {
    display: block; 
    width: 100%; 
    height: auto; 
    max-width: 100%; /* Adjusted for smaller screens */
    padding: 10px; /* Reduced padding for smaller screens */
    max-height: 100vh; /* Adjusted for smaller screens */
    margin: 0 auto; /* Center the image */
  }

  .webServices_content01,
  .webServices_content02 {
    padding: 5px; /* Reduced padding for smaller screens */
    width: 100%; 
    text-align: center;
  }

  .webServices_content01 h3,
  .webServices_content02 h3 {
    font-size: 18px; /* Slightly smaller font size for smaller screens */
    text-align: start;
    justify-content: center;
  }

  .webServices_content01 p,
  .webServices_content02 p {
    font-size: 15px; /* Reduced font size for smaller screens */
    text-align: center;
    justify-content: center;
  }

  .webicon_li li {
    font-size: 14px; /* Slightly smaller font size */
    padding: 6px; /* Reduced padding for smaller screens */
  }
}
