.DGContent_Development21 {
  height: 80vh;
  width: 100%;
  background: linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)),
    url("../../DevopsAgile/DevopsOverview/Assets/1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 20vh;
}

.DGContent_Development21 h1 {
  color: white;
  font-weight: 700;
  padding: 5px;
  font-size: 60px;
}

.DGContent_Development21 p {
  color: white;
  font-size: 30px;
}

.DGButton_Contain {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.DGButton_Contain button {
  background-color: #faf9f9;
  color: #786e6e;
  border: none;
  padding: 10px 20px;
  margin: -10px 80px 0px 0px;
  font-size: 26px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.DGButton_Contain button:hover {
  color: orange;
}

.DGOverview_content p {
  color: black;
  font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}
@media only screen and (max-width: 1024px) {
  .DGContent_Development21 {
    height: 60vh;
    padding-top: 10vh;
  }

  .DGContent_Development21 h1 {
    font-size: 40px;
    padding: 0;
  }

  .DGContent_Development21 p {
    font-size: 20px;
  }

  .DGButton_Contain {
    flex-direction: row;
    align-items: center;
  }

  .DGButton_Contain button {
    margin: 10px 0;
    font-size: 20px;
    padding: 8px 16px;
  }

  .DGOverview_content p {
    font-size: 18px;
    margin-left: 10px;
    display: block;
  }
}
@media only screen and (max-width: 769px) {
  .DGContent_Development21 {
    height: 50vh;
    padding-top: 8vh;
  }

  .DGContent_Development21 h1 {
    font-size: 32px;
  }

  .DGContent_Development21 p {
    font-size: 18px;
  }

  .DGButton_Contain {
    flex-direction: column;
    align-items: center;
  }

  .DGButton_Contain button {
    margin: 8px 0;
    font-size: 18px;
    padding: 6px 12px;
  }

  .DGOverview_content p {
    font-size: 16px;
    margin-left: 5px;
  }
}
@media only screen and (max-width: 430px) {
  .DGContent_Development21 {
    height: 40vh;
    padding-top: 5vh;
  }

  .DGContent_Development21 h1 {
    font-size: 24px;
  }

  .DGContent_Development21 p {
    font-size: 16px;
  }

  .DGButton_Contain {
    flex-direction: column;
    align-items: center;
  }

  .DGButton_Contain button {
    margin: 6px 0;
    font-size: 16px;
    padding: 5px 10px;
  }

  .DGOverview_content p {
    font-size: 14px;
    margin-left: 0;
  }
}
