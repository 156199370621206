.webcontainer_blog{
    height: 120vh;
    width: 100%;
    background-color: rgb(239,247,245);
    margin-top: 60px;
}
.webBlog_Studies {
    height: 25vh;
    width: 10vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .webBlog_Studies h1 {
    color: rgb(231,238,237);
    font-size: 15vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin: 0;
  }
  
  .webBlog_Studies p {
    position: absolute;
    color: rgb(14,43,63);
    font-size: 40px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-weight: 500;
  }
  
  .webBlog_1 {
    margin: 10px 0;
  }

  .webzoom-img {
    overflow: hidden;
  }
  
  .webzoom-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s ease;
  }
  
  .webzoom-img img:hover {
    transform: scale(1.2);
  }
  
  .webBlog_1_sub {
    align-items: center;
    text-align: start;
    display: flex;
  }
  
  .webBlog_1_sub p {
    padding-left: 100px;
  }
  
  @media (max-width: 1024px) {
    .webcontainer_blog {
        height: auto; /* Adjust height if necessary */
    }
    .webBlog_Studies h1{
     padding-top: 30px;

    }

    .webzoom-img {
      padding-top: 50px;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .col-lg-4 {
        flex: 1 1 100%; /* Each column takes full width */
        max-width: 100%;
        margin-bottom: 20px;
    }

    .webBlog_1_sub p {
        padding-left: 20px; /* Adjust padding for smaller screens */
    }
  }

  @media (max-width: 769px) {
    .webcontainer_blog {
        height: auto; /* Adjust height if necessary */
        padding: 10px; /* Add some padding for smaller screens */
    }

    .webBlog_Studies h1 {
        padding-top: 20px;
        font-size: 24px; /* Adjust font size for mobile */
        text-align: center; /* Center the heading */
    }

    .webzoom-img {
        padding-top: 30px;
        width: 100%; /* Ensure the image scales properly */
        height: auto; /* Maintain aspect ratio */
    }

    .row {
        display: flex;
        flex-direction: column; /* Stack elements vertically */
    }

    .col-lg-4 {
        flex: 1 1 100%; /* Each column takes full width */
        max-width: 100%;
        margin-bottom: 15px;
    }

    .webBlog_1_sub p {
        padding-left: 15px; /* Adjust padding for smaller screens */
        padding-right: 15px; /* Add right padding for symmetry */
        font-size: 16px; /* Adjust font size for better readability */
    }
}
@media (max-width: 430px) {
  .webcontainer_blog {
      height: auto; /* Adjust height for smaller screens */
      padding: 8px; /* Reduce padding slightly for very small screens */
  }

  .webBlog_Studies h1 {
      padding-top: 15px;
      font-size: 20px; /* Further reduce font size for better fit */
      text-align: center; /* Keep heading centered */
  }

  .webzoom-img {
      padding-top: 20px;
      width: 100%; /* Ensure full-width image scaling */
      height: auto; /* Maintain aspect ratio */
  }

  .row {
      display: flex;
      flex-direction: column; /* Stack elements vertically */
  }

  .col-lg-4 {
      flex: 1 1 100%; /* Full width for each column */
      max-width: 100%;
      margin-bottom: 12px; /* Reduce margin for better spacing */
  }

  .webBlog_1_sub p {
      padding-left: 10px; /* Adjust padding for smaller screens */
      padding-right: 10px; /* Symmetrical right padding */
      font-size: 14px; /* Reduce font size for readability */
  }
}




   

