.box1 {
    border-radius: 2px;
    border: 1px solid #b1afaf;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
    background-color: rgb(253, 240, 241);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
}

.box1 img {
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.box1 h4 {
    padding-top: 20px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.box1 span {
    padding-top: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.box1:hover {
    box-shadow: 0 4px 8px rgba(21, 16, 16, 0.1);
    transform: translateY(-5px);
    background-color: rgb(241, 123, 131);
}

.box1 img:hover {
    transform: scale(1.1);
    color: white;
}

.box1 h4:hover {
    color: #f4f7fa;
    transform: translateY(-5px);
}

.box1 span:hover {
    color: #f4f7fa;
    transform: translateY(-5px);
}

.box2 {
    border-radius: 2px;
    border: 1px solid #b1afaf;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
    background-color: rgb(229, 248, 243);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
}

.box2 img {
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.box2 h4 {
    padding-top: 20px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.box2 span {
    padding-top: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.box2:hover {
    box-shadow: 0 4px 8px rgba(21, 16, 16, 0.1);
    transform: translateY(-5px);
    background-color: rgb(24, 209, 160);
}

.box2 img:hover {
    transform: scale(1.1);
}

.box2 h4:hover {
    color: #efeff0;
    transform: translateY(-5px);
}

.box2 span:hover {
    color: #f4f7fa;
    transform: translateY(-5px);
}


.box3 {
    border-radius: 2px;
    border: 1px solid #b1afaf;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
    background-color: rgb(216, 216, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
}

.box3 img {
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.box3 h4 {
    padding-top: 20px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.box3 span {
    padding-top: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.box3:hover {
    box-shadow: 0 4px 8px rgba(21, 16, 16, 0.1);
    transform: translateY(-5px);
    background-color: rgb(59, 133, 224);
}

.box3 img:hover {
    transform: scale(1.1);
}

.box3 h4:hover {
    color: #f2f5f7;
    transform: translateY(-5px);
}

.box3 span:hover {
    color: #f4f7fa;
    transform: translateY(-5px);
}

.box4 {
    border-radius: 2px;
    border: 1px solid #b1afaf;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
    background-color: rgb(216, 216, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
}

.box4 img {
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.box4 h4 {
    padding-top: 20px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.box4 span {
    padding-top: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.box4:hover {
    box-shadow: 0 4px 8px rgba(21, 16, 16, 0.1);
    transform: translateY(-5px);
    background-color: rgb(59, 133, 224);
}

.box4 img:hover {
    transform: scale(1.1);
}

.box4 h4:hover {
    color: #f2f5f7;
    transform: translateY(-5px);
}

.box4 span:hover {
    color: #f4f7fa;
    transform: translateY(-5px);
}

.box5 {
    border-radius: 2px;
    border: 1px solid #b1afaf;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
    background-color: rgb(244, 228, 229);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
}

.box5 img {
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.box5 h4 {
    padding-top: 20px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.box5 span {
    padding-top: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.box5:hover {
    box-shadow: 0 4px 8px rgba(21, 16, 16, 0.1);
    /* Add shadow on hover */
    transform: translateY(-5px);
    /* Slightly move the box upwards on hover */
    background-color: rgb(247, 134, 143);
}

.box5 img:hover {
    transform: scale(1.1);
    /* Scale the image on hover */
}

.box5 h4:hover {
    color: #f3f5f7;
    /* Change text color on hover */
    transform: translateY(-5px);
    /* Slightly move the heading upwards on hover */
}

.box5 span:hover {
    color: #f4f7fa;
    transform: translateY(-5px);
}


.box6 {
    border-radius: 2px;
    border: 1px solid #b1afaf;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
    background-color: rgb(255, 255, 181);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
}

.box6 img {
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.box6 h4 {
    padding-top: 20px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    /* Smooth transition for hover effects */
}

.box6 span {
    padding-top: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    /* Smooth transition for hover effects */
}

/* Hover effects */

.box6:hover {
    box-shadow: 0 4px 8px rgba(21, 16, 16, 0.1);
    /* Add shadow on hover */
    transform: translateY(-5px);
    /* Slightly move the box upwards on hover */
    background-color: rgb(252, 252, 68);
    /* Change background color on hover */
}

.box6 img:hover {
    transform: scale(1.1);
    /* Scale the image on hover */
}

.box6 h4:hover {
    color: #f9fbfd;
    /* Change text color on hover */
    transform: translateY(-5px);
    /* Slightly move the heading upwards on hover */
}

.box6 span:hover {
    color: #f4f7fa;
    transform: translateY(-5px);
}

.companies img {
    height: 35px;
    width: auto;
    margin: 10px;
    display: block;
}


