.BMContent_Development6 {
    height: 80vh;
    width: 100%;
    background: 
    linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)), 
    url("../../Business/BusinessOverview/Assets/BPM1.png");    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20vh;
  }

  .BMContent_Development6 h1{
     color: white;
     font-weight: 700;
     padding: 5px;
     font-size: 60px;

  }
  
  .BMContent_Development6 p{
    color: white;
    font-size: 30px;

 }
 
.BMButton_Contain {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .BMButton_Contain button {
    background-color: #faf9f9;
    color: #786e6e;
    border: none;
    padding: 10px 20px;
    margin: -10px 80px 0px 0px;
    font-size: 26px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .BMButton_Contain button:hover {
color: orange;  }

.BMOverview_content  p{
  color: black;
 font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}
/* Mobile query for screens with a width of 1024px or less */
@media screen and (max-width: 1024px) {
  .BMContent_Development6 {
    height: auto;
    padding-top: 10vh;
  }

  .BMContent_Development6 h1 {
    font-size: 48px;
    padding: 10px;
  }

  .BMContent_Development6 p {
    font-size: 24px;
    padding: 0 10px;
  }

  .BMButton_Contain {
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }

  .BMButton_Contain button {
    margin: 10px 0;
    font-size: 22px;
    padding: 8px 15px;
  }

  .BMOverview_content p {
    font-size: 18px;
    margin-left: 10px;
  }
}
/* Mobile query for screens with a width of 769px or less */
@media screen and (max-width: 769px) {
  .BMContent_Development6 {
    height: auto;
    padding-top: 8vh;
  }

  .BMContent_Development6 h1 {
    font-size: 36px;
    padding: 8px;
  }

  .BMContent_Development6 p {
    font-size: 20px;
    padding: 0 8px;
  }

  .BMButton_Contain {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .BMButton_Contain button {
    margin: 8px 0;
    font-size: 20px;
    padding: 6px 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .BMOverview_content p {
    font-size: 16px;
    margin-left: 8px;
  }
}
/* Mobile query for screens with a width of 430px or less */
@media screen and (max-width: 430px) {
  .BMContent_Development6 {
    height: auto;
    padding-top: 6vh;
  }

  .BMContent_Development6 h1 {
    font-size: 28px;
    padding: 6px;
  }

  .BMContent_Development6 p {
    font-size: 18px;
    padding: 0 6px;
  }

  .BMButton_Contain {
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
  }

  .BMButton_Contain button {
    margin: 6px 0;
    font-size: 18px;
    padding: 5px 10px;
    width: 80%; /* Ensure buttons take up most of the width */
  }

  .BMOverview_content p {
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px; /* Adjust margin for better spacing on small screens */
  }
}
