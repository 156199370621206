.mobcontainer_blog{
    height: 120vh;
    width: 100%;
    background-color: rgb(239,247,245);

}
.mobBlog_Studies {
    height: 25vh;
    width: 10vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .mobBlog_Studies h1 {
    color: rgb(231,238,237);
    font-size: 15vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin: 0;
  }
  
  .mobBlog_Studies p {
    position: absolute;
    color: rgb(14,43,63);
    font-size: 40px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-weight: 500;
  }
  
  .mobBlog_1 {
    margin: 10px 0;
  }

  .mobzoom-img {
    overflow: hidden;
  }
  
  .mobzoom-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s ease;
  }
  
  .mobzoom-img img:hover {
    transform: scale(1.2);
  }
  
  .mobBlog_1_sub {
    align-items: center;
    text-align: start;
    display: flex;
  }
  
  .mobBlog_1_sub p {
    padding-left: 100px;
  }
  
  @media (max-width: 1024px) {
    .mobBlog_Studies {
        height: 20vh; /* Adjusted height */
    }

    .mobBlog_Studies h1 {
        font-size: 10vh; /* Adjusted font size */
    }

    .mobBlog_Studies p {
        font-size: 30px; /* Adjusted font size */
    }

    .mobBlog_1 {
        margin: 5px 0; /* Adjusted margin */
    }

    .mobBlog_1_sub p {
        padding-left: 50px; /* Adjusted padding */
    }
}
@media (max-width: 769px) {
  .mobBlog_Studies {
      height: 15vh; /* Further adjusted height */
      margin-top: 50px;
  }

  .mobBlog_Studies h1 {
      font-size: 8vh; /* Further adjusted font size */
  }

  .mobBlog_Studies p {
      font-size: 24px; /* Further adjusted font size */
  }

  .mobBlog_1 {
      margin: 5px 0; /* Maintained margin for smaller screens */
  }

  .mobBlog_1_sub {
      flex-direction: column; /* Stack elements vertically */
      align-items: flex-start; /* Align to the start of the container */
      text-align: left; /* Ensure text is aligned left */
  }

  .mobBlog_1_sub p {
      padding-left: 0; /* Remove left padding */
      padding-top: 5px; /* Add top padding for spacing */
  }
}
@media (max-width: 430px) {
  .mobBlog_Studies {
      height: 10vh; /* Further reduced height */
  }

  .mobBlog_Studies h1 {
      font-size: 5vh; /* Further reduced font size */
  }

  .mobBlog_Studies p {
      font-size: 18px; /* Further reduced font size */
  }

  .mobBlog_1 {
      margin: 2px 0; /* Reduced margin */
  }

  .mobBlog_1_sub {
      flex-direction: column; 
      align-items: center; 
      text-align: center; 
  }

  .mobBlog_1_sub p {
      padding-left: 0; 
      padding-top: 2px; 
  }
}
