.APPContent_Development18 {
    height: 80vh;
    width: 100%;
    background: 
    linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)), 
    url("../../Mobile/MobileOverview/Assets/Mb6.jpg");    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20vh;
  }

  .APPContent_Development18 h1{
     color: white;
     font-weight: 700;
     padding: 5px;
     font-size: 60px;

  }
  
  .APPContent_Development18 p{
    color: white;
    font-size: 30px;

 }
 
.APPButton_Contain {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .APPButton_Contain button {
    background-color: #faf9f9;
    color: #786e6e;
    border: none;
    padding: 10px 20px;
    margin: -10px 80px 0px 0px;
    font-size: 26px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .APPButton_Contain button:hover {
color: orange;  }

.APPOverview_content  p{
  color: black;
 font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .APPContent_Development18 {
    height: 60vh; /* Adjusted for smaller screens */
    padding-top: 10vh; /* Reduced padding to fit the content better */
  }

  .APPContent_Development18 h1 {
    font-size: 40px; /* Adjusted font size for better visibility */
    
  }

  .APPContent_Development18 p {
    font-size: 20px; /* Reduced font size */
    
  }

  .APPButton_Contain {
    flex-direction: row; /* Stack buttons vertically */
    align-items: center;
    margin-top: 10px; /* Reduced margin */
  }

  .APPButton_Contain button {
    margin: 10px 0; /* Adjusted margin for stacking buttons */
    font-size: 20px; /* Adjusted font size */
  }

  .APPOverview_content p {
    font-size: 18px; /* Adjusted font size for readability */
    margin-left: 10px; /* Reduced margin */
  }
}
@media screen and (max-width: 769px) {
  .APPContent_Development18 {
    height: 50vh; /* Further reduced height for smaller screens */
    padding-top: 8vh; /* Adjusted padding */
  }

  .APPContent_Development18 h1 {
    font-size: 35px; /* Further reduced font size */
  }

  .APPContent_Development18 p {
    font-size: 18px; /* Adjusted font size */
    
  }

  .APPButton_Contain {
    flex-direction: column; /* Buttons remain stacked vertically */
    align-items: center;
    margin-top: 8px; /* Reduced margin */
    text-align: center;
    justify-content: center;
  }

  .APPButton_Contain button {
    margin: 8px 0; /* Adjusted margin for stacking buttons */
    font-size: 18px; /* Further reduced font size */
  }

  .APPOverview_content p {
    font-size: 16px; /* Adjusted font size for better readability */
    margin-left: 5px; /* Reduced margin */
  }
}

