.DGxContent_Development5 {
  height: 80vh;
  width: 100%;
  background: linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)),
    url("../../DigitalCustomer/DigitalCustomerOverview/Assets/DCx1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 20vh;
}

.DGxContent_Development5 h1 {
  color: white;
  font-weight: 700;
  padding: 5px;
  font-size: 60px;
}

.DGxContent_Development5 p {
  color: white;
  font-size: 30px;
}

.DGxButton_Contain {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.DGxButton_Contain button {
  background-color: #faf9f9;
  color: #786e6e;
  border: none;
  padding: 10px 20px;
  margin: -10px 80px 0px 0px;
  font-size: 26px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.DGxButton_Contain button:hover {
  color: orange;
}

.DGxOverview_content p {
  color: black;
  font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}
/* Media query for screens with a width of 1024px or less */
@media (max-width: 1024px) {
  .DGxContent_Development5 {
    height: 60vh; /* Adjusted height for smaller screens */
    padding-top: 10vh; /* Adjusted padding for smaller screens */
  }

  .DGxContent_Development5 h1 {
    font-size: 40px; /* Reduced font size for better fit */
  }

  .DGxContent_Development5 p {
    font-size: 20px; /* Reduced font size for better fit */
  }

  .DGxButton_Contain {
    flex-direction: row; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    margin-top: 10px; /* Adjusted margin */
  }

  .DGxButton_Contain button {
    margin: 10px 0; /* Adjusted margin for buttons */
    font-size: 30px; /* Reduced font size for buttons */
  }

  .DGxOverview_content p {
    font-size: 16px; /* Adjusted font size for smaller screens */
    margin-left: 0; /* Removed left margin */
    padding: 0 20px; /* Added padding for better readability */
  }
}
/* Media query for screens with a width of 769px or less */
@media (max-width: 769px) {
  .DGxContent_Development5 {
    height: 50vh; /* Further reduced height for smaller screens */
    padding-top: 5vh; /* Adjusted padding for smaller screens */
  }

  .DGxContent_Development5 h1 {
    font-size: 30px; /* Further reduced font size for better fit */
  }

  .DGxContent_Development5 p {
    font-size: 18px; /* Further reduced font size for better fit */
  }

  .DGxButton_Contain {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    margin-top: 10px; /* Adjusted margin */
  }

  .DGxButton_Contain button {
    margin: 10px 0; /* Adjusted margin for buttons */
    font-size: 18px; /* Reduced font size for buttons */
    padding: 8px 16px; /* Adjusted padding for buttons */
  }

  .DGxOverview_content p {
    font-size: 14px; /* Further reduced font size for smaller screens */
    margin-left: 0; /* Removed left margin */
    padding: 0 15px; /* Adjusted padding for better readability */
  }
}
/* Media query for screens with a width of 430px or less */
@media (max-width: 430px) {
  .DGxContent_Development5 {
    height: 40vh; /* Further reduced height for very small screens */
    padding-top: 5vh; /* Adjusted padding for very small screens */
  }

  .DGxContent_Development5 h1 {
    font-size: 24px; /* Reduced font size to fit on smaller screens */
  }

  .DGxContent_Development5 p {
    font-size: 16px; /* Reduced font size for better readability on small screens */
  }

  .DGxButton_Contain {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    margin-top: 10px; /* Adjusted margin */
  }

  .DGxButton_Contain button {
    margin: 8px 0; /* Reduced margin for buttons */
    font-size: 16px; /* Reduced font size for buttons */
    padding: 6px 12px; /* Adjusted padding for buttons */
  }

  .DGxOverview_content p {
    font-size: 12px; /* Further reduced font size for very small screens */
    margin-left: 0; /* Removed left margin */
    padding: 0 10px; /* Adjusted padding for better readability */
  }
}

