@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
 
*{
  font-family: "Poppins", sans-serif;
}

.htbackground {
    height: 80vh;
    width: 100%;
    /* background:  url('./Assets/Over1.jpg'); */
    background-image: url('./Assets/Over1.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 20vh;
}

.htbackground h1 {
  color: white;
  font-weight: 700;
  padding: 5px;
  font-size: 60px;
}

.htbackground p {
  color: white;
  font-size: 30px;
}

.Button_Contain {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.Button_Contain button {
  background-color: #faf9f9;
  color: #786e6e;
  border: none;
  padding: 10px 20px;
  margin: -10px 80px 0px 0px;
  font-size: 26px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Button_Contain button:hover {
  color: orange;
}

.Overview_content p {
  color: black;
  font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}
