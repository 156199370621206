.DGService_Content_01 {
  background-color: rgb(248, 243, 237);
  display: flex;
  align-items: center;
  height: 120vh;
}
.DGServices_content01 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: oblique 40deg;
}

.DGicon_li i {
  color: rgb(255, 123, 0);
}

.DGicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.DGServices_content01 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
}

.DGImage_service01 img {
  padding-top: 80px;
  width: 70vh;
  height: 100vh;
}

.DGService_Content_02 {
  background-color: rgb(241, 241, 241);
  display: flex;
  align-items: center;
  height: 120vh;
}
.DGServices_content02 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: oblique 40deg;
}

.DGicon_li i {
  color: rgb(255, 123, 0);
}

.DGicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.DGServices_content02 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
}

.DGImage_service02 img {
  padding-top: 40px;
  width: 80vh;
  height: 100vh;
}
@media (max-width: 1024px) {
  .DGService_Content_01, .DGService_Content_02 {
    height: auto; /* Adjust height to fit content */
    flex-direction: row;
  }

  .DGImage_service01 img, .DGImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto;
    padding-top: 0;
  }

  .col-lg-7, .col-lg-5 {
    width: 100%;
    margin: 0;
  }

  .DGServices_content01, .DGServices_content02 {
    padding: 20px;
  }

  .DGServices_content01 h3, .DGServices_content02 h3 {
    font-size: 22px; /* Adjust font size for better readability */
  }

  .DGServices_content01 p, .DGServices_content02 p {
    font-size: 16px; /* Adjust font size for better readability */
  }

  .DGicon_li li {
    font-size: 14px; /* Adjust font size for better readability */
  }
}
/* Media query for screens with a width of 769px or less */

@media (max-width: 769px) {
  .DGService_Content_01, .DGService_Content_02 {
    height: auto; /* Adjust height to fit content */
    flex-direction: column;
  }

  .DGImage_service01 img, .DGImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto;
    padding-top: 0;
  }

  .col-lg-7, .col-lg-5 {
    width: 100%;
    margin: 0;
  }

  .DGServices_content01, .DGServices_content02 {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .DGServices_content01 h3, .DGServices_content02 h3 {
    font-size: 20px; /* Adjust font size for better readability */
  }

  .DGServices_content01 p, .DGServices_content02 p {
    font-size: 14px; /* Adjust font size for better readability */
  }

  .DGicon_li li {
    font-size: 12px; /* Adjust font size for better readability */
  }
}
/* Media query for screens with a width of 430px or less */

@media (max-width: 430px) {
  .DGService_Content_01, .DGService_Content_02 {
    height: auto; /* Adjust height to fit content */
    flex-direction: column;
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .DGImage_service01 img, .DGImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto;
    padding-top: 0;
  }

  .col-lg-7, .col-lg-5 {
    width: 100%;
    margin: 0;
  }

  .DGServices_content01, .DGServices_content02 {
    padding: 10px; /* Further reduce padding for very small screens */
  }

  .DGServices_content01 h3, .DGServices_content02 h3 {
    font-size: 18px; /* Adjust font size for better readability */
  }

  .DGServices_content01 p, .DGServices_content02 p {
    font-size: 12px; /* Adjust font size for better readability */
  }

  .DGicon_li li {
    font-size: 10px; /* Adjust font size for better readability */
  }
}
