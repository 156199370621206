.CLService_Content_01 {
  background-color: rgb(248, 243, 237);
  display: flex;
  align-items: center;
  height: 120vh;
}
.CLServices_content01 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: oblique 40deg;
}

.CLicon_li i {
  color: rgb(255, 123, 0);
}

.CLicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.CLServices_content01 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
  color: rgb(255, 123, 0);

}

.CLImage_service01 img {
  padding-top: 80px;
  width: 70vh;
  height: 100vh;
}

.CLService_Content_02 {
  background-color: rgb(241, 241, 241);
  display: flex;
  align-items: center;
  height: 120vh;
}
.CLServices_content02 p {
  padding: 10px;
  color: rgb(14, 14, 14);
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: oblique 40deg;
}

.CLicon_li i {
  color: rgb(255, 123, 0);
}
.CLicon_li{
  list-style: none;
}

.CLicon_li li {
  padding: 10px;
  font-family: oblique 40deg;
}
.CLServices_content02 h3 {
  padding-top: 25px;
  font-family: oblique 40deg;
  font-weight: 700;
  color: rgb(255, 123, 0);

}

.CLImage_service02 img {
  padding-top: 40px;
  width: 80vh;
  height: 100vh;
}

/* Mobile query for screens with a width of 1024px */
@media (max-width: 1024px) {
  .CLService_Content_01,
  .CLService_Content_02 {
    height: auto; /* Adjust height to auto for better flexibility */
    padding: 20px; /* Add padding for better spacing */
  }

  .CLServices_content01,
  .CLServices_content02 {
    font-size: 16px; /* Adjust font size for better readability */
  }

  .CLImage_service01 img,
  .CLImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    padding-top: 20px; /* Adjust padding for better spacing */
  }

  .CLImage_service01,
  .CLImage_service02 {
    text-align: center; /* Center align images for smaller screens */
  }

  .col-lg-7,
  .col-lg-5 {
    width: 100%; /* Stack columns vertically */
    margin-bottom: 20px; /* Add margin for spacing */
  }

  .col-lg-4 {
    width: 100%; /* Ensure images take full width */
    margin-bottom: 20px; /* Add margin for spacing */
  }

  .CLicon_li li {
    font-size: 14px; /* Adjust font size for readability */
    padding: 5px; /* Adjust padding for better spacing */
  }

  h1 {
    font-size: 24px; /* Adjust font size for headings */
    text-align: center; /* Center align headings for smaller screens */
  }

  h3 {
    font-size: 20px; /* Adjust font size for subheadings */
  }
}
/* Mobile query for screens with a width of 769px */
@media (max-width: 769px) {
  .CLService_Content_01,
  .CLService_Content_02 {
    height: auto; /* Adjust height to auto for better flexibility */
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .CLServices_content01,
  .CLServices_content02 {
    font-size: 14px; /* Further reduce font size for better readability */
  }

  .CLImage_service01 img,
  .CLImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    padding-top: 10px; /* Reduce padding for better spacing */
  }

  .CLImage_service01,
  .CLImage_service02 {
    text-align: center; /* Center align images for smaller screens */
  }

  .col-lg-7,
  .col-lg-5 {
    width: 100%; /* Stack columns vertically */
    margin-bottom: 15px; /* Reduce margin for spacing */
  }

  .col-lg-4 {
    width: 100%; /* Ensure images take full width */
    margin-bottom: 15px; /* Reduce margin for spacing */
  }

  .CLicon_li li {
    font-size: 12px; /* Further adjust font size for readability */
    padding: 5px; /* Adjust padding for better spacing */
  }

  h1 {
    font-size: 20px; /* Reduce font size for headings */
    text-align: center; /* Center align headings for smaller screens */
  }

  h3 {
    font-size: 18px; /* Reduce font size for subheadings */
  }
}
/* Mobile query for screens with a width of 430px */
@media (max-width: 430px) {
  .CLService_Content_01,
  .CLService_Content_02 {
    height: auto; /* Adjust height to auto for better flexibility */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .CLServices_content01,
  .CLServices_content02 {
    font-size: 12px; /* Further reduce font size for better readability */
  }

  .CLImage_service01 img,
  .CLImage_service02 img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    padding-top: 5px; /* Reduce padding for better spacing */
  }

  .CLImage_service01,
  .CLImage_service02 {
    text-align: center; /* Center align images for smaller screens */
  }

  .col-lg-7,
  .col-lg-5 {
    width: 100%; /* Stack columns vertically */
    margin-bottom: 10px; /* Reduce margin for spacing */
  }

  .col-lg-4 {
    width: 100%; /* Ensure images take full width */
    margin-bottom: 10px; /* Reduce margin for spacing */
  }

  .CLicon_li li {
    font-size: 10px; /* Further adjust font size for readability */
    padding: 5px; /* Adjust padding for better spacing */
  }

  h1 {
    font-size: 18px; /* Reduce font size for headings */
    text-align: center; /* Center align headings for smaller screens */
  }

  h3 {
    font-size: 16px; /* Reduce font size for subheadings */
  }
}
