.AITech_sup {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;

}
.AITech_sup h1{
    color: rgb(215, 255, 246);
    font-size: 25vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 
}
.AITech_sup p {
    position: absolute;
    color: rgb(51, 130, 89);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
.AIimg_content_tools img{
    height: 12vh;
}
@media screen and (max-width: 1024px) {
    .AITech_sup {
      height: 20vh;
      width: 100%; /* Make the container full-width */
      margin: 10px auto; /* Center and reduce margin */
    }
  
    .AITech_sup h1 {
      font-size: 20vh; /* Adjust the font size */
    }
  
    .AITech_sup p {
      font-size: 25px; /* Adjust the font size */
    }
  
    .AIimg_content_tools img {
      height: 10vh; /* Adjust the image height */
    }
  }
  @media screen and (max-width: 769px) {
    .AITech_sup {
      height: 18vh;
      width: 100%; /* Keep the container full-width */
      margin: 8px auto; /* Center and further reduce margin */
    }
  
    .AITech_sup h1 {
      font-size: 1vh; /* Decrease the font size for smaller screens */
    }
  
    .AITech_sup p {
      font-size: 20px; /* Adjust the font size for better readability */
    }
  
    .AIimg_content_tools img {
      height: 8vh; /* Adjust the image height to fit the smaller screen */
    }
  }
  @media screen and (max-width: 430px) {
    .AITech_sup {
      height: 15vh;
      width: 100%; /* Full-width container */
      margin: 5px auto; /* Center and reduce margin further */
    }
  
    .AITech_sup h1 {
      font-size: 6vh; /* Decrease the font size for very small screens */
    }
  
    .AITech_sup p {
      font-size: 18px; /* Adjust the font size for better readability */
    }
  
    .AIimg_content_tools img {
      height: 6vh; /* Reduce image height to fit the smaller screen */
    }
  }
  